import Vue from "vue";
import router from "./router";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import translate from 'i18n-jsautotranslate'

NProgress.configure({ showSpinner: false }); // NProgress Configuration

router.beforeEach(async (to, from, next) => {
    NProgress.start(); // start progress bar
    setTimeout(() => {
        translate.execute(); // 翻译
    },100);
    document.title = `博雅公学-${to.meta.title}`; //根据路由跳转显示标签标题

    next()
});


//后置守卫
router.afterEach(() => {
    NProgress.done(); // finish progress bar
});
