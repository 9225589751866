import Vue from "vue";
import { getInfo, isWhitelist, login, logout } from "@/api/testApi";
import { ACCESS_TOKEN, USER_INFO } from "@/store/mutation-types";
import { resetRouter } from "@/router";

const state = {
  schoolInfo: {},
  token: '',
  userBaseInfo: { username: '未登录' },
  userId: '',
  userRoles: '',
  loginStatus: false
}
const mutations = {
  SET_SHOW_TEXT(state, showText) {
    state.showText = showText
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USER_BASE_INFO: (state, userBaseInfo) => {
    state.userBaseInfo = userBaseInfo
  },
  SET_USER_ROLES: (state, userRoles) => {
    state.userRoles = userRoles
  },
  SET_USER_ID: (state, userId) => {
    state.userId = userId
  },
  SET_LOGIN_STATUS: (state, loginStatus) => {
    state.loginStatus = loginStatus
  },
  SET_SCHOOL_INFO: (state, info) => {
    state.schoolInfo = info;
  }
}

const actions = {
  initSchool({ commit }, info) {
    commit('SET_SCHOOL_INFO', info)
  },
  // 登录
  Login({ commit }, userInfo) {
    commit('SET_USER_BASE_INFO', userInfo)
    commit('SET_LOGIN_STATUS', true)
    Vue.ls.set(ACCESS_TOKEN, userInfo.token, 12 * 60 * 60 * 1000)
    Vue.ls.set(USER_INFO, userInfo, 12 * 60 * 60 * 1000)
  },
  //退出登录
  Logout({ commit, state }) {
    return new Promise((resolve) => {
      commit('SET_TOKEN', '')
      commit('SET_USER_ROLES', '')
      commit('SET_USER_BASE_INFO', {})
      commit('SET_LOGIN_STATUS', false)
      Vue.ls.remove(ACCESS_TOKEN)
      Vue.ls.remove(USER_INFO)
      resolve();
    })
  },
  //根据token获取用户信息
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      let params = { token: Vue.ls.get(ACCESS_TOKEN) };
      getInfo(params).then(response => {
        console.log(response)
        if (response.code === 200) {
          const result = response.result
          const userInfo = result.userInfo
          commit('SET_USER_ID', userInfo.userId)
          commit('SET_USER_BASE_INFO', userInfo)
          commit('SET_USER_ROLES', userInfo.roles)
          commit('SET_LOGIN_STATUS', true)
          Vue.ls.set(USER_INFO, userInfo, 30 * 60 * 1000)
          resolve({ roles: userInfo.roles })
        } else {
          resolve(response)
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  //网络白名单
  isWhitelist({ commit, state }) {
    return new Promise((resolve, reject) => {
      isWhitelist().then(response => {
        resolve({ viewCode: response.code })
      }).catch(error => {
        reject(error)
      })
    })
  },
}
export default {
  state,
  mutations,
  actions,
  /**
   * 为true时两种方法调用action
   * 1....mapActions('空间名', [
   'action名'
   ])
   * 2.this.$store.dispatch('空间名/action名', this.$route)
   *
   *
   * 为false时 这样调用 this.$store.dispatch('action名', this.$route)，所以此时action名不能重复
   */
  namespaced: true,
}
