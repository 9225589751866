import { axios } from "@/utils/request";

/**
 * get请求
 * @param url 请求api
 * @param parameter 请求参数
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getAction(url, parameter) {
    return axios({
        url: url,
        method: "get",
        params: parameter,
    });
}

/**
 * post请求
 * @param url 请求api
 * @param parameter 请求参数
 * @returns {Promise<AxiosResponse<any>>}
 */
export function postAction(url, parameter) {
    return axios({
        url: url,
        method: "post",
        data: parameter,
    });
}

export function postAction1(url, parameter) {
    return axios({
        url: url,
        method: "post",
        data: parameter,
        contentType: "application/json", //请求头类型
    });
}

export function postAction2(url, parameter) {
    return axios({
        url: url,
        method: "post",
        data: parameter,
        timeout: 300000, // 请求超时时间
    });
}

/**
 * post method= {post | put}
 */
export function httpAction(url, parameter, method) {
    return axios({
        url: url,
        method: method,
        data: parameter,
    });
}

/**
 * 同步请求
 * @param url 请求地址
 * @param JsonParam 请求参数
 * @param method 请求方法
 */
export function httpAction1(url,JsonParam,method){
    // 创建XmlHttpRequest请求对象
    const xhr = new XMLHttpRequest();
    xhr.open(method, url, false); // 同步请求，true：异步请求
    // 指定请求内容类型
    xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
    // 发送请求
    xhr.send(JSON.stringify(JsonParam));
    // 以json格式解析返回数据
    return JSON.parse(xhr.responseText);
}

/**
 * put请求
 * @param url
 * @param parameter
 * @returns {Promise<AxiosResponse<any>>}
 */
export function putAction(url, parameter) {
    return axios({
        url: url,
        method: "put",
        data: parameter,
    });
}

/**
 *  delete请求
 */
export function deleteAction(url, parameter) {
    return axios({
        url: url,
        method: "delete",
        params: parameter,
    });
}

/**
 * 下载文件 用于excel导出
 * @param url
 * @param parameter
 * @returns {*}
 */
export function downFile(url, parameter) {
    return axios({
        url: url,
        params: parameter,
        method: "get",
        responseType: "blob",
    });
}
