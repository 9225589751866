// 按需全局引入 element ui组件
import Vue from "vue";
import {
    Button,
    MessageBox,
    Input,
    Form,
    FormItem,
    Row,
    Col,
    Card,
    Checkbox,
    Steps,
    Step,
    Select,
    Option,
    DatePicker,
    RadioGroup,
    Radio,
    Upload,
    Cascader
} from "element-ui";

import { message } from "@/config/message";

Vue.use(Form); Vue.use(FormItem); Vue.use(Button); Vue.use(Input); Vue.use(Row); Vue.use(Col); Vue.use(Card); Vue.use(Checkbox);
Vue.use(Steps); Vue.use(Step); Vue.use(Select); Vue.use(Option); Vue.use(DatePicker); Vue.use(RadioGroup); Vue.use(Radio); Vue.use(Upload);
Vue.use(Cascader);

Vue.prototype.$message = message;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
