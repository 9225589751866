const getters = {
    showText: state => state.app.showText,
    userRoles: state => state.app.userRoles,
    userBaseInfo: state => state.app.userBaseInfo,
    userId: state => state.app.userId,
    loginStatus: state => state.app.loginStatus,
    permission_routes: state => state.permission.routes,
    school_info: state => state.app.schoolInfo
}
export default getters
