import {postAction} from "@/api/index";

//登录
const login=(params)=>postAction("/user/login",params);
//获取用户信息
const getInfo=(params)=>postAction("/user/getInfo",params);

//退出
const logout=(params)=>postAction("/user/logout",params);

//白名单
const isWhitelist=(params)=>postAction("/sys/isWhitelist",params);

export {
    login,
    getInfo,
    logout,
    isWhitelist
}
