import "core-js/stable";
import "regenerator-runtime/runtime";
import 'amfe-flexible'

/**
 * 重置浏览器css样式。
 * reset的目的是将所有的浏览器的自带样式重置掉，这样更易于保持各浏览器渲染的一致性。
 * normalize的理念则是尽量保留浏览器的默认样式，不进行太多的重置，而尽力让这些样式保持一致并尽可能与现代标准相符合。
 */
import "normalize.css/normalize.css";

import Vue from 'vue'
import App from './App.vue'
import router from "@/router"; //vue-router
import store from "@/store"; //vuex
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import translate from 'i18n-jsautotranslate'
translate.setUseVersion2() //设置使用v2.x 版本
translate.selectLanguageTag.show = false //是否显示切换栏
translate.listener.start() //监控页面动态渲染的文本进行自动翻译
Vue.prototype.$translate = translate
import { variableJudge } from "@/utils"
import * as tool from "@/utils/tool.js"
import "@/assets/icons";
import '@/assets/styles/common.scss'
import '@/plugins/element-ui' //按需引入的element组件
import '@/assets/styles/theme/changeTheme.js'
import './permission' //页面权限(vue-router的全局守卫配置）

const defaultSettings = require("@/config/index.js");

// defaultSettings.mockFlag && require('@/mock/user')

// 浏览器存储
import Storage from 'vue-ls';

//引入vue懒加载
import VueLazyload from "vue-lazyload";

//没有页面加载中的图片和页面图片加载错误的图片显示
// Vue.use(VueLazyload);

//方法二：  显示页面图片加载中的图片和页面图片加载错误的图片
//引入图片
import errorPng from './assets/images/404.png'
// import loading from '@/assets/images/load.jpg'
//注册图片懒加载
Vue.use(VueLazyload, {
    // preLoad: 1.3, //提前加载高度（数字 1 表示 1 屏的高度）
    error: errorPng,//图片错误的替换图片路径(可以使用变量存储)
    // loading: loading,//正在加载的图片路径(可以使用变量存储)
    attempt: 1 //加载错误后最大尝试次数
})

Vue.use(Viewer) //图片查看大图
Viewer.setDefaults({
    Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})

const storageOptions = {
    namespace: 'mobile__', // key prefix
    name: 'ls', // name variable Vue.[ls] or this.[$ls],
    storage: 'local', // storage name session,local, memory  //memory刷新页面就清除了
}

Vue.use(Storage, storageOptions)

Vue.prototype.$$ = variableJudge;

Vue.prototype.$tool = tool;

Vue.config.productionTip = false;

/**
 * 将px转为vw,用于页面的内联样式
 */
function pxToVW(px) {
    if (/%/ig.test(px)) { // 有百分号%，特殊处理，表述px是一个有百分号的数，比如：90%
        return px
    } else {
        return (parseFloat(px) / 13.66) + 'vw' // 这里的37.5，和rootValue值对应
    }
}

Vue.prototype.$pxToVW = pxToVW

// import {USER_INFO} from "@/store/mutation-types";
// 同一浏览器只能登录一个用户,效果第一个窗口登录后，打开第二个窗口，第二个窗口登录后再点击第一个窗口，第一个窗口的用户会切换为后登录的用户
// window.addEventListener("visibilitychange", function () { //这个方法是监测浏览器窗口发生变化的时候执行
//     if (store.getters.loginStatus && document.hidden == false && Vue.ls.get(USER_INFO) && store.getters.userId != Vue.ls.get(USER_INFO).userId) {
//         store.commit('app/SET_USER_ID', Vue.ls.get(USER_INFO).userId)
//         window.location.reload();
//     }
// });

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
