

/**
 * @abstract 拼接路由参数  '/:id' + url
 */
export const p = (url) => url;


// 首页导航栏路由
export const navigationRouterMap = [
    {
        path: p('/admin'),
        redirect: p('/admin/home')
    },
    {
        path: p('/admin/home'),
        name: "home",
        component: () => import('@/views/home'),
        meta: {
            title: "首页",
            type: 'navtion',
            path: '/admin/home'
        },
    },
    {
        path: p('/admin/schoolState'),
        name: "schoolState",
        component: () => import('@/views/schoolState'),
        meta: {
            title: "学部状况",
            type: 'navtion',
            path: '/admin/schoolState'
        },
    },
    {
        path: p('/admin/schoolMien'),
        name: "schoolMien",
        component: () => import('@/views/schoolMien'),
        meta: {
            title: "校园风采",
            type: 'navtion',
            path: '/admin/schoolMien'
        },
    },
    {
        path: p('/admin/newsInfo'),
        redirect: '/admin/newsInfo/list',
        name: "news",
        component: () => import('@/views/newsInfo'),
        children: [
            {
                name: "news-list",
                path: p('/admin/newsInfo/list'),
                component: () => import('@/views/newsInfo-list'),
                meta: {
                    title: "新闻动态",
                    type: 'navtion',
                    path: '/admin/newsInfo'
                },
            },
            {
                name: "news-detail",
                path: p('/admin/newsInfo/detail'),
                component: () => import('@/views/newsInfo-detail'),
                meta: {
                    title: "新闻动态",
                    type: 'navtion',
                    path: '/admin/newsInfo'
                },
            }
        ],
        meta: {
            title: "新闻动态",
            type: 'navtion',
            path: '/admin/newsInfo'
        },
    },
    {
        path: p('/admin/studyTrain'),
        name: "home",
        component: () => import('@/views/studyTrain'),
        meta: {
            title: "出国留学培训",
            type: 'navtion',
            path: '/admin/studyTrain'
        },
    },
]
/**
 * constantRouterMap
 * 没有权限要求的基本页
 * 所有角色都可以访问
 * 不需要动态判断权限的路由
 */
export const constantRouterMap = [
    {
        path: "/",
        redirect: '/admin'
    },
    {
        path: p('/admin'),
        name: "admin",
        component: () => import('@/components/Layouts/index'),
        children: navigationRouterMap
    },
    {
        path: "/404",
        name: "404",
        component: () => import('@/views/exception/404'),
    },
    { path: '*', redirect: '/404' }
];

/**
 * 动态菜单，走权限控制
 */
export const asyncRouterMap = [
];
