import * as qiniu from 'qiniu-js'
import Vue from "vue"
import { USER_INFO } from "@/store/mutation-types"
import { clientUploadToken, getBasedHaskeyTradeUrl } from "@/api/common"
const defaultSettings = require("@/config/index.js");
/**
 * 生成页面标题
 */
export function getPageTitle(pageTitle) {
  if (pageTitle) {
    return `${pageTitle}${defaultSettings.title}`
  }
  return `${defaultSettings.title}`
}

/**
 * 判断是否为外部资源
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}



// 封装上传图片的方法
export function uploadImageWithAntiLeech(file) {

  return new Promise((resolve, reject) => {

    console.log(file, 'file');

    clientUploadToken().then(res => {
      var config = {
        useCdnDomain: true,
        region: qiniu.region.z2 //区域
      };

      // 设置防盗链参数
      var putExtra = {
        fname: file.name, // 文件原文件名
        params: {
          "save-key": "$(etag)",
          // "referer": `http://192.168.0.251:8081`  // 设置防盗链referer参数
        }
      };

      var key = 'stuEnroll/' + new Date().getTime() + file.name;

      // 执行上传
      var observable = qiniu.upload(file, key, res.result);

      console.log(observable, 'observable');


      observable.subscribe({
        next(res) {
        },
        error(err) {
          reject(err)
        },
        complete(res) {
          let userInfo = Vue.ls.get(USER_INFO);
          let params = {
            createUserId: userInfo.id,
            createUserName: userInfo.name || 'xxx',
            size: file.size,
            name: file.name,
            resourceHash: res.hash,
            resourceKey: res.key,
          }
          getBasedHaskeyTradeUrl(params).then(res => {
            resolve(res)
          })
        }
      });

    })
  })
}


export function variableJudge(obj, keyName, tag = '?.') {
  if (!obj) return undefined
  let keys = keyName.split(tag)
  return keys.reduce((objNew, keyItem) => {
    if (keyItem === '') return objNew
    if (keyItem.indexOf('.') !== -1) return variableJudge1(objNew, keyItem, '.')
    return objNew?.[keyItem]
  }, obj)
}

